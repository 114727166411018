import { useState } from 'react';
import {
  ArrayField,
  Create,
  Datagrid,
  FunctionField,
  SaveButton,
  SimpleForm,
  TextField,
  Toolbar,
  useCreate,
  useGetList,
  useNotify,
  useRecordContext,
  useRedirect,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';

import DateFieldIgnoringZone from '../../../components/DateFieldIgnoringZone';
import { MoneyField } from '../../../components/MoneyField';
import RoutePaths from '../../../routes';
import Tile from '../../../shared/components/Tile';
import CancellationBillingDetails from '../cancellation-billing-details';
import {
  CANCELLATION_TYPES,
  mountRequestPayload,
  parseErrors,
  parseResult,
  transformPetsForCancellation,
} from '../utils/cancellation-utils';
import CancellationForm from './cancellation-form';

export default function CancellationCreate(props) {
  const notify = useNotify();
  const redirect = useRedirect();
  const location = useLocation();
  const { punks1925AdjustWellnessRefundAndObLogic } = useFlags();

  const record = location.state?.record;

  if (!record) {
    return <div>Start a cancellation from the customer tab...</div>;
  }

  const customer = record.customer;

  const [cancellations, setCancellations] = useState([]);
  const [pets, setPets] = useState([]);
  const [key, setKey] = useState(1);
  const [results, setResults] = useState([]);

  const { isLoading, refetch: loadPets } = useGetList(
    RoutePaths.pets,
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
      filter: { user_id: customer.id },
    },
    {
      enabled: !!customer.id,
      onSuccess: ({ data }) => {
        setPets(transformPetsForCancellation(data));
      },
      onError: () => {
        setPets([]);
        notify('Error: Unable to load Pets', { type: 'error' });
      },
    }
  );

  const [create] = useCreate();

  function handleOnAddItem(itemToAdd) {
    setCancellations([...cancellations, itemToAdd]);
    setKey(key + 1);
  }

  function handleOnDeleteItem(itemToDelete) {
    const itemsAfterDelete = cancellations.filter(
      item => item.pet_id !== itemToDelete.pet_id
    );
    setCancellations(itemsAfterDelete);
    setKey(key + 1);
  }

  function handleOnSave() {
    if (cancellations.length === 0) {
      notify('Error: Cancellation request should have at least one item', {
        type: 'error',
      });
      return;
    }
    setResults([]);
    create(
      RoutePaths.cancellations,
      {
        data: mountRequestPayload(cancellations),
        meta: { returnJson: 'DATA' },
      },
      {
        onSuccess: data => {
          let { results, cancellationsWithErrors } = parseResult(
            cancellations,
            data
          );

          if (cancellationsWithErrors.length > 0) {
            setResults(results);
            setCancellations(cancellationsWithErrors);
            loadPets();
          } else {
            redirect(`/${RoutePaths.customers}/${customer.id}/show`);
          }
        },
        onError: err => {
          setResults(parseErrors(cancellations, err));
        },
      }
    );
  }

  function CancellationCreateToolbar({ cancellations, ...rest }) {
    return (
      <Toolbar {...rest}>
        <SaveButton
          label={'Submit Cancellation' + (cancellations.length > 1 ? 's' : '')}
          alwaysEnable
        />
      </Toolbar>
    );
  }

  function DeleteItemButton() {
    const record = useRecordContext();
    return (
      <Button
        sx={{ cursor: 'pointer', color: '#F55353' }}
        color='primary'
        variant='inherit'
        onClick={() => handleOnDeleteItem(record)}
      >
        Delete
      </Button>
    );
  }

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ width: '75%' }}>
        <Create {...props}>
          <SimpleForm
            redirect='show'
            toolbar={<CancellationCreateToolbar cancellations={cancellations} />}
            onSubmit={handleOnSave}
          >
            <Typography
              style={{ fontSize: '20px', fontWeight: '500', marginBottom: '24px' }}
            >
              Customer Details
            </Typography>

            <Tile
              title={'Customer First Name'}
              content={customer.first_name}
              style={{ width: '472px' }}
            />

            <Tile
              title={'Customer Last Name'}
              content={customer.last_name}
              style={{ width: '472px', marginTop: '16px' }}
            />

            <Tile
              title={'Customer Email'}
              content={customer.email}
              style={{ width: '472px', marginTop: '16px' }}
            />

            <Tile
              title={'Customer PKN'}
              content={customer.pumpkin_id}
              style={{ width: '472px', marginTop: '16px', marginBottom: '60px' }}
            />

            <CancellationBillingDetails userId={customer.id} />

            <Typography
              style={{ fontSize: '20px', fontWeight: '500', marginBottom: '18px' }}
            >
              Cancellations
            </Typography>

            <div style={{ width: '100%' }}>
              <ul>
                {results.map((result, index) => (
                  <li key={index}>
                    <Typography
                      style={{ color: result.error ? '#F7636F' : '#3EC28F' }}
                    >
                      {result.pet_name}: {result.message}
                    </Typography>
                  </li>
                ))}
              </ul>
            </div>

            <ArrayField
              label=''
              source='cancellations'
              record={{ cancellations: cancellations }}
            >
              <Datagrid
                empty={
                  <Typography>
                    Cancellation request should have at least one item.
                  </Typography>
                }
                sx={{
                  '& .RaDatagrid-headerCell': {
                    fontSize: '12px',
                    fontWeight: '500',
                    lineHeight: '20px',
                    letterSpacing: '0.4px',
                  },
                }}
                bulkActionButtons={false}
              >
                <DateFieldIgnoringZone
                  style={{
                    fontSize: '12px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    letterSpacing: '0.4px',
                  }}
                  label='Request Date'
                  source='cancellation_requested_date'
                />
                <TextField label='Pet' source='pet_name' />
                <FunctionField
                  label='Type'
                  sx={{
                    fontSize: '12px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    letterSpacing: '0.4px',
                  }}
                  render={(record = {}) => {
                    for (let key in CANCELLATION_TYPES) {
                      if (CANCELLATION_TYPES[key].id === record.cancellation_type) {
                        return CANCELLATION_TYPES[key].name;
                      }
                    }
                    return '';
                  }}
                />
                <FunctionField
                  label='Plan / Policy / Wellness'
                  render={(record = {}) => {
                    return [record.policy, record.plan, record.wellness]
                      .filter(Boolean)
                      .map(item => (
                        <div
                          style={{
                            fontSize: '12px',
                            fontWeight: '400',
                            lineHeight: '20px',
                            letterSpacing: '0.4px',
                          }}
                          key={item}
                        >
                          {item}
                        </div>
                      ));
                  }}
                />
                <TextField label='Reason' source='reason' />
                <DateFieldIgnoringZone
                  style={{
                    fontSize: '12px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    letterSpacing: '0.4px',
                  }}
                  label='End Date'
                  source='end_date'
                />
                <MoneyField
                  style={{
                    fontSize: '12px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    letterSpacing: '0.4px',
                  }}
                  label='Insurance Refund'
                  source='insurance_refund'
                />
                {punks1925AdjustWellnessRefundAndObLogic ? (
                  <FunctionField
                    label='Prevent or Wellness Refund'
                    render={(record = {}) => {
                      if (record.prevent_refund && record.prevent_refund < 0) {
                        return (
                          <MoneyField
                            style={{
                              fontSize: '12px',
                              fontWeight: '400',
                              lineHeight: '20px',
                              letterSpacing: '0.4px',
                            }}
                            record={{ prevent_refund: record.prevent_refund * -1 }}
                            source='prevent_refund'
                          />
                        );
                      } else if (
                        record.wellness_refund &&
                        record.wellness_refund < 0
                      ) {
                        return (
                          <MoneyField
                            style={{
                              fontSize: '12px',
                              fontWeight: '400',
                              lineHeight: '20px',
                              letterSpacing: '0.4px',
                            }}
                            record={{
                              wellness_refund: record.wellness_refund * -1,
                            }}
                            source='wellness_refund'
                          />
                        );
                      } else {
                        return '';
                      }
                    }}
                  />
                ) : null}
                {punks1925AdjustWellnessRefundAndObLogic ? (
                  <FunctionField
                    label='Outstanding Balance'
                    render={(record = {}) => {
                      if (record.prevent_refund && record.prevent_refund >= 0) {
                        return (
                          <MoneyField
                            style={{
                              fontSize: '12px',
                              fontWeight: '400',
                              lineHeight: '20px',
                              letterSpacing: '0.4px',
                            }}
                            record={{ prevent_refund: record.prevent_refund }}
                            source='prevent_refund'
                          />
                        );
                      } else if (
                        record.wellness_refund &&
                        record.wellness_refund >= 0
                      ) {
                        return (
                          <MoneyField
                            style={{
                              fontSize: '12px',
                              fontWeight: '400',
                              lineHeight: '20px',
                              letterSpacing: '0.4px',
                            }}
                            record={{ wellness_refund: record.wellness_refund }}
                            source='wellness_refund'
                          />
                        );
                      } else {
                        return '';
                      }
                    }}
                  />
                ) : null}

                {!punks1925AdjustWellnessRefundAndObLogic ? (
                  <MoneyField label='Prevent Refund' source='prevent_refund' />
                ) : null}
                {!punks1925AdjustWellnessRefundAndObLogic ? (
                  <MoneyField label='Wellness Refund' source='wellness_refund' />
                ) : null}

                <DeleteItemButton />
              </Datagrid>
            </ArrayField>
          </SimpleForm>
        </Create>
      </div>

      <div style={{ width: '25%' }}>
        <CancellationForm
          key={key}
          customerId={customer.id}
          isLoading={isLoading}
          pets={pets}
          onAddItem={handleOnAddItem}
          cancellationsInProgress={cancellations.map(c => c.pet_id)}
          cancellationsInProgressList={cancellations}
        />
      </div>
    </div>
  );
}
