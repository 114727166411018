import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';

import { formatCurrency, parseAndFormatDate } from '../../../../shared/utils';

const useStyles = makeStyles(
  {
    root: {
      padding: '16px 16px 24px',
    },
    title: {
      fontSize: '16px',
      fontWeight: '700',
      lineHeight: '24px',
      letterSpacing: '0.15px',
      color: 'black',
    },
    tableContainer: {
      margin: '16px 0',
      whiteSpace: 'nowrap',
    },
    tableHeader: {
      textTransform: 'uppercase',
    },
    tableRow: {
      cursor: 'pointer',
    },
    hasBundle: {
      fontWeight: 'bold',
      color: '#01A299',
    },
  },
  { name: 'claims-list' }
);

function ClaimsList({ claims }) {
  const classes = useStyles();

  function formatDate(value) {
    return value ? parseAndFormatDate(value) : '';
  }

  function handleClick(row) {
    window.open(`/claims/${row.claim_number}/show`, '_blank');
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.title}>Claims</Typography>

      <TableContainer className={classes.tableContainer}>
        <Table size='small'>
          <TableHead className={classes.tableHeader}>
            <TableRow>
              <TableCell>Claim Id</TableCell>
              <TableCell>Insurance Id</TableCell>
              <TableCell>Prevent Id</TableCell>
              <TableCell>Wellness Id</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Pet</TableCell>
              <TableCell>Loss Date</TableCell>
              <TableCell>Submitted</TableCell>
              <TableCell>Completed</TableCell>
              <TableCell>Claimed</TableCell>
              <TableCell>Approved</TableCell>
              <TableCell>Deducted</TableCell>
              <TableCell>Paid</TableCell>
              <TableCell>Bundle</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {claims
              .sort((l, r) => -l.date_submitted.localeCompare(r.date_submitted))
              .map(row => (
                <TableRow
                  hover={true}
                  key={row.claim_number}
                  className={classes.tableRow}
                  onClick={() => {
                    handleClick(row);
                  }}
                >
                  <TableCell component='th' scope='row'>
                    {row.claim_number}
                  </TableCell>
                  <TableCell>{row.insurance_id}</TableCell>
                  <TableCell>{row.prevent_id}</TableCell>
                  <TableCell>{row.wellness_id}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.claim_type}</TableCell>
                  <TableCell>{row.pet_name}</TableCell>
                  <TableCell>{formatDate(row.loss_date)}</TableCell>
                  <TableCell>{formatDate(row.date_submitted)}</TableCell>
                  <TableCell>{formatDate(row.date_completed)}</TableCell>
                  <TableCell>
                    {formatCurrency(row.amount_claimed, { areCents: true })}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(row.amount_approved, { areCents: true })}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(row.amount_deducted, { areCents: true })}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(row.amount_paid, { areCents: true })}
                  </TableCell>
                  <TableCell className={row.has_bundle ? classes.hasBundle : null}>
                    {row.has_bundle ? 'YES' : 'NO'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

ClaimsList.propTypes = {
  claims: PropTypes.arrayOf(
    PropTypes.shape({
      claim_number: PropTypes.string,
      insurance_id: PropTypes.string,
      wellness_id: PropTypes.string,
      status: PropTypes.string,
      claim_type: PropTypes.string,
      pet_name: PropTypes.string,
      loss_date: PropTypes.string,
      date_submitted: PropTypes.string,
      date_completed: PropTypes.string,
      amount_claimed: PropTypes.number,
      amount_approved: PropTypes.number,
      amount_deducted: PropTypes.number,
      amount_paid: PropTypes.number,
      has_bundle: PropTypes.bool,
    })
  ),
};

export default ClaimsList;
