import { CreateButton, useRecordContext } from 'react-admin';
import PropTypes from 'prop-types';

function AddClaimAttachmentButton({
  routes,
  disableButton,
  label = 'ADD CLAIM ATTACHMENTS',
  ...props
}) {
  const record = useRecordContext();

  const claimRecord = {
    claim_id: record.id,
    user_id: record.customer_id,
    pet_id: record.pet_id,
    vet_id: record.vet_id,
  };

  return (
    <CreateButton
      disabled={disableButton}
      label={label}
      resource={`/${routes.documentsV2}/create`}
      state={{ record: claimRecord }}
      {...props}
    />
  );
}

AddClaimAttachmentButton.propTypes = {
  routes: PropTypes.object,
  disableButton: PropTypes.bool,
  label: PropTypes.string,
};

export default AddClaimAttachmentButton;
