import { useState } from 'react';
import {
  FunctionField,
  Show,
  SimpleShowLayout,
  useCreate,
  useNotify,
  useRefresh,
  useShowController,
} from 'react-admin';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PropTypes from 'prop-types';

import { useAllMedicalRecordsRequests } from '@pumpkincare/medical-records';
import { AddClaimAttachmentButton } from '@pumpkincare/shared';
import { useCustomer, useOpsUser, usePet } from '@pumpkincare/users';

import useBooleanInput from '../../../hooks/useBooleanInput';
import RoutePaths from '../../../routes';
import { AuditLogsLink } from '../../audit-logs';
import ClaimAttachmentTable from '../../claims/claim-attachment-table';
import EditClaimAttachment from '../../claims/edit-claim-attachment';
import MedicalRecordsRequestAccordion from '../../medical-records/view/medical-records-request-accordion';
import CreateDocsModal from '../create-docs-modal/create-docs-modal';
import PetCancelModal from '../pet-cancel-modal';
import PetDetailHeader from './pet-detail-header';
import PetPlansDatagrid from './pet-plan-datagrid';
import petShowStyle from './pet-show-style';
import PolicyDatagrid from './policy-datagrid';
import WellnessDatagrid from './wellness-datagrid';

function UserTitle({ user_id, pet_name }) {
  const { data: customerData, isLoading: isCustomerLoading } = useCustomer(user_id);
  return !isCustomerLoading ? (
    <span>
      Customer:{' '}
      {customerData
        ? `${customerData.first_name} ${customerData.last_name} - ${customerData.pumpkin_id} - ${pet_name}`
        : ''}
    </span>
  ) : null;
}

UserTitle.propTypes = {
  user_id: PropTypes.string,
  pet_name: PropTypes.string,
};

function PetShow() {
  const { id } = useParams();
  const classes = petShowStyle();

  const [isCancelPetModalOpen, toggleCancelPetModal] = useBooleanInput();
  const [policyPlan, setPolicyPlan] = useState();
  const [policyId, setPolicyId] = useState();
  const [isEditAttachmentModalOpen, toggleEditAttachmentModal] = useBooleanInput();
  const [claimAttachment, setClaimAttachment] = useState();
  const { mvx163PreClaimsHealthReview } = useFlags();

  const { data: petData, isLoading: isPetDataLoading } = usePet(id);

  const [isCreateDocModalOpen, toggleCreateDocsModal] = useBooleanInput();
  const [create, { isLoading: isSaving }] = useCreate();
  const refresh = useRefresh();
  const notify = useNotify();
  const { data: currentOpsUserData } = useOpsUser();
  const { record } = useShowController();

  function handlePolicyCancelClick(policy, petName) {
    setPolicyPlan({ ...policy, pet_name: petName });
    toggleCancelPetModal();
  }

  function handlePlanCancelClick(plan, petName) {
    setPolicyPlan({ ...plan, pet_name: petName });
    toggleCancelPetModal();
  }

  function handleShowCreateDocsModal(policyId) {
    setPolicyId(policyId);
    toggleCreateDocsModal();
  }

  function handleCreateDocsClick() {
    create(
      `${RoutePaths.policies}/${policyId}/regenerate-docs`,
      {
        data: {},
        returnJson: 'RAW',
      },
      {
        onSuccess: () => {
          toggleCreateDocsModal();
          refresh();
          notify('Documents successfully enqueued', {
            type: 'success',
          });
        },
        onError: error => {
          toggleCreateDocsModal();
          notify(
            `There was an error while trying to create docs: ${error.message}`,
            {
              type: 'error',
            }
          );
        },
      }
    );
  }

  function handleClaimAttachmentClick(claimAttachment) {
    if (claimAttachment.claim_id) {
      setClaimAttachment(claimAttachment);
    } else {
      setClaimAttachment({ ...claimAttachment, claim_id: record.id });
    }
    toggleEditAttachmentModal();
  }

  const {
    data: medicalRecordsRequestsObject,
    isLoading: medicalRecordsRequestsLoading,
    refetch: refetchMedicalRecordsRequests,
  } = useAllMedicalRecordsRequests({ petId: record?.id });

  return (
    <Show
      title={
        isPetDataLoading ? (
          <CircularProgress />
        ) : (
          <UserTitle user_id={petData?.user_id} pet_name={petData?.name} />
        )
      }
    >
      <SimpleShowLayout>
        <PetDetailHeader
          auditLog={
            <FunctionField
              label={'Audit Logs'}
              render={(record = {}) => {
                return <AuditLogsLink filter={{ pet_id: record.id }} />;
              }}
            />
          }
        />
        <FunctionField
          render={(record = {}) => {
            return (
              <PolicyDatagrid
                record={record}
                onCreateDocsClick={handleShowCreateDocsModal}
                onPetCancelClick={policy =>
                  handlePolicyCancelClick(policy, record.name)
                }
              />
            );
          }}
        />

        {petData?.latest_pet_plan &&
        Object.keys(petData.latest_pet_plan).length > 0 ? (
          <FunctionField
            render={(record = {}) => {
              return (
                <PetPlansDatagrid
                  onPetCancelClick={plan => handlePlanCancelClick(plan, record.name)}
                />
              );
            }}
          />
        ) : null}
        {petData?.wellness ? (
          <FunctionField
            render={(record = {}) => {
              return <WellnessDatagrid record={record} />;
            }}
          />
        ) : null}
        <PetCancelModal
          isOpen={isCancelPetModalOpen}
          onClose={toggleCancelPetModal}
          policy_plan={policyPlan}
        />
        {petData ? (
          <CreateDocsModal
            isOpen={isCreateDocModalOpen}
            onClose={toggleCreateDocsModal}
            onConfirm={handleCreateDocsClick}
            petData={petData}
            isSaving={isSaving}
          />
        ) : null}
        {mvx163PreClaimsHealthReview ? (
          <>
            <MedicalRecordsRequestAccordion
              medicalRecordsRequestsObject={medicalRecordsRequestsObject}
              isLoading={medicalRecordsRequestsLoading}
              refetch={refetchMedicalRecordsRequests}
              disableRequestCreation={false}
              petId={record?.id}
              userId={record?.user_id}
            />
            <Accordion
              square
              defaultExpanded
              classes={{ root: classes.accordionRoot }}
            >
              <AccordionSummary
                classes={{
                  root: classes.summaryRoot,
                  content: classes.summaryContent,
                }}
                aria-controls='all-medical-record-requests'
                id='all-medical-record-requests'
                disableRipple
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
              >
                <Typography variant='h6'>Medical Record Files</Typography>
              </AccordionSummary>
              <AccordionDetails classes={{ root: classes.details }}>
                <>
                  <EditClaimAttachment
                    open={isEditAttachmentModalOpen}
                    onClose={toggleEditAttachmentModal}
                    claimAttachment={claimAttachment}
                    currentOpsUserData={currentOpsUserData}
                    fileTypeDisabled={true}
                  />
                  <div style={{ width: '650px', marginBottom: '38px' }}>
                    <ClaimAttachmentTable
                      showEditButton
                      showDeleteButton
                      onEditClick={handleClaimAttachmentClick}
                      currentOpsUserData={currentOpsUserData}
                      claimId={record?.id}
                      disableButton={false}
                      claimRecord={record}
                      showFileType={false}
                      filter={{ type: 'medical_record', pet_id: record?.id }}
                      target='pet_id'
                      changeReference={RoutePaths.documentsV2}
                    />
                  </div>
                  <AddClaimAttachmentButton
                    routes={RoutePaths}
                    disableButton={isSaving}
                    label='NEW MEDICAL RECORD'
                    variant='outlined'
                    sx={{
                      padding: '6px 8px',
                      border: '1px solid #CDCFD3',
                      '& svg': { color: '#5368F5;' },
                      '&:disabled': {
                        '& svg': { color: '#00000042' },
                      },
                    }}
                  />
                </>
              </AccordionDetails>
            </Accordion>
          </>
        ) : null}
      </SimpleShowLayout>
    </Show>
  );
}

export default PetShow;
